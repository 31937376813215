export const SUBSCRIBE_BUTTON_MORE_INFORMATION = {
  information: [
    {
      copy: 'This plugin allows easy inclusion of the Podlove Subscribe Button. Put it in your sidebar with a simple widget or include the button in pages or posts with a simple shortcode.',
      ctaLabel: 'To the plugin page',
      ctaPath: 'https://wordpress.org/plugins/podlove-subscribe-button/',
      icon: '../images/icons/wordpress--white.png',
      title: 'Subscribe Button WordPress Plugin',
    },
    {
      copy: 'The Podlove Web Player WordPress plugin offers an integrated Subscribe Button for easy subscription to your podcast.',
      ctaLabel: 'To the docs',
      ctaPath: 'https://docs.podlove.org/podlove-web-player/wordpress-plugin/subscribe-button/',
      icon: '../images/icons/chapter-marks--white.png',
      title: 'Activate the Subscribe Button within the Podlove Web Player',
    },
    {
      ctaLabel: 'Subscribe Button on Github',
      ctaPath: 'https://github.com/podlove/podlove-subscribe-button',
      copy: 'Do you want to take a look into the machinery or help us with development? Then check out the repository.',
      icon: '../images/icons/github--white.png',
      title: 'Github Repository',
    },
  ]
}