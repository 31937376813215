import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import SubscribeButtonFeatures from '../components/subscribeButtonFeatures'
import SubscribeButtonMoreInformation from '../components/subscribeButtonMoreInformation'
import SubscribeButtonPreview from '../components/subscribeButtonPreview'
import { SUBSCRIBE_BUTTON_BENEFITS_DATA } from '../content/subscribeButtonBenefits'

const NAV_ITEMS = [
  {
    name: 'Preview',
    path: '#preview',
  },
  {
    name: 'Features',
    path: '#features',
  },
  {
    name: 'Installation',
    path: '#installation',
  },
]

const IndexPage = () => (
  <Layout
    subHeaderNavItems={NAV_ITEMS}
    subHeaderNavTitle={'Podlove Subscribe Button'}
    subHeaderColor={'podlove-green-500'}
  >
    <Seo title="Home" />
    <Hero
      copy="Automatic downloads make podcast content easy to access and flexible to use. With them you create a strong bond between your podcast and your audience – and your listeners will never miss an episode. Our universal and easy-to-use Podlove Subscribe Button helps your listeners subscribe to your podcast with their player client of choice."
      cta="Install"
      ctaColor={'podlove-green-500'}
      headline="Podlove Subscribe Button"
      benefits={SUBSCRIBE_BUTTON_BENEFITS_DATA.data}
      showColorAnimation
    />
    <SubscribeButtonPreview />
    <SubscribeButtonFeatures />
    <SubscribeButtonMoreInformation />
  </Layout>
)

export default IndexPage
