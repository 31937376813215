import React from 'react'
import { getRandomColor } from '../helpers/colors'
import ArticleHeader from '../elements/articleHeader'
import { SUBSCRIBE_BUTTON_PREVIEW_DATA } from '../content/subscribeButtonPreview'

const ClientSideOnlyLazy = React.lazy(() =>
  import('../elements/SubscribeButton')
)

const SubscribeButtonPreview = () => {
  const isSSR = typeof window === 'undefined'

  // console.log(PodloveSubscribeButton)
  return (
    <div
      className="
        bg-blue-800
        flex
        flex-col
        flex-wrap
        font-sans
        items-center
        justify-center
        px-12
        py-20
        w-screen
        space-y-8
        text-white
        text-center
        tracking-wide
      "
      id="preview"
    >
      <ArticleHeader
        subtitle="Every podcast is unique. That's why we developed three ways to use the Podlove Subscribe Button with your specific environment. Take a look at the three options below, or contact us, if none fits your publishing situation."
        title="Give it a try! It's free"
      />
      <div
        className="
          w-full
        "
      >
        { !isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy />
          </React.Suspense>
        )}
      </div>
      <div
        className="
          flex
          flex-wrap
          px-4
          md:px-12
          max-w-6xl
        "
      >
        { SUBSCRIBE_BUTTON_PREVIEW_DATA.data.map((item) => 
          <div
            key={JSON.stringify(item)}
            className="
              text-left
              px-4
              py-12
              w-full
              sm:w-1/2
              lg:w-1/3
            "
          >
            <div
              className={`
                bg-${getRandomColor()}
                h-10
                items-center
                justify-center
                flex
                my-4
                rounded-md
                text-${item.color}-800
                w-10
              `}
            >
              <img src={item.icon} />
            </div>
            <h3
              className="
                font-bold
                pb-4
                text-xl
              "
            >{item.title}</h3>
            <p>{item.copy}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubscribeButtonPreview