import React from 'react'

import ArticleHeader from '../elements/articleHeader'
import { SUBSCRIBE_BUTTON_FEATURES_DATA } from '../content/subscribeButtonFeatures'

const SubscribeButtonFeatures = () => (
  <div
    id="features"
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
  >
    <ArticleHeader
      subtitle="The Podlove Subscribe Button is a simple web widget that every web site can use to make it super easy for users to launch an app or podcast cloud service and to handover the right feed URL. The button automatically checks the OS used and has a builtin podcast client database with the clients’ capabilities and methods on how to pass feed information into it. This database is constantly updated when new clients show up or existing ones receive new functionality."
      title="One Button To Subscribe Them All"
    />
    <div
      className="
        flex
        flex-wrap
        px-4
        md:px-12
        max-w-6xl
      "
    >
      { SUBSCRIBE_BUTTON_FEATURES_DATA.features.map((item) =>
        <div
          key={JSON.stringify(item)}
          className={`
            flex
            flex-col
            py-12
            text-left
            md:w-1/2
          `}
        >
          <div
            className="
            bg-blue-200
            md:h-72
            mx-4
            rounded-lg
            overflow-hidden
            "
          >
            <img
              className="
                w-full
              "
              src={item.image}
            />
          </div>
          <h3
            className="
              font-bold
              mx-4
              py-4
              text-xl
            "
          >{item.title}</h3>
        </div>
      )}
    </div>
  </div>
)

export default SubscribeButtonFeatures