export const SUBSCRIBE_BUTTON_PREVIEW_DATA = {
  data: [
    {
      copy: 'Cool! Then you can activate the Podlove Subscribe Button with a single step: Just activate the Subscribe Button in the WordPress Widget and you\'re good to go.',
      icon: '../images/icons/heart--white.png',
      title: 'You are using the Podlove Publisher?',
    },
    {
      copy: 'If you\'re using WordPress with another podcasting plugin: We got you covered! Just install the Podlove Subscribe Button Plugin to use it in your WordPress blog.',
      icon: '../images/icons/wordpress--white.png',
      title: 'You are using WordPress without the Podlove Publisher?',
    },
    {
      copy: 'No worries! You can use the generator below, to customize the Podlove Subscribe Button and generate your individual script for your website.',
      icon: '../images/icons/web--white.png',
      title: 'You are not using WordPress at all?',
    },
  ]
}