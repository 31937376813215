export const SUBSCRIBE_BUTTON_FEATURES_DATA = {
  features: [
    {
      image: '../images/screenshot_1.png',
      title: 'Several integrated clients',
    },
    {
      image: '../images/artboard_1.png',
      title: 'Smart color config',
    },
    {
      image: '../images/artboard_2.png',
      title: 'Multiple languages and styles: outline, filled, frameless',
    },
    {
      image: '../images/screenshot_4.png',
      title: 'Integrate your cover and use your own button',
    },
    {
      image: '../images/screenshot_3.png',
      title: 'Multiple sizes: small, medium, big',
    },
    {
      image: '../images/artboard_3.png',
      title: 'Multiple formats: standard, cube, auto-width',
    },
    {
      image: '../images/artboard_4.png',
      title: 'Easy configuration',
    },
    {
      image: '../images/screenshot_2.png',
      title: 'Works on desktop, mobile, and in the cloud',
    },
  ]
}