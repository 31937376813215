export const SUBSCRIBE_BUTTON_BENEFITS_DATA = {
  data: [
    {
      copy: 'By subscribing to your podcast, your listeners will never miss a new episode. And with the Podlove Subscribe button all it takes are a few clicks.',
      icon: '../images/icons/podcast--white.png',
      title: 'Click to subscribe',
    },
    {
      copy: 'The Podlove Subscribe Buttons helps your listeners by choosing a podcast player the want to subscribe your podcast in – and even suggests apps should they not have any installed on their device.',
      icon: '../images/icons/audio-file--white.png',
      title: 'Freedom of choice',
    },
    {
      copy: 'Your listeners don\'t have to know the feed address or what a feed is in the first place. They just hit the button – Podlove does the rest.',
      icon: '../images/icons/subscribe--white.png',
      title: 'No feed worries',
    },
  ]
}